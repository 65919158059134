"use client";

import { QueryClientProvider, type QueryClient } from "@tanstack/react-query";
import { loggerLink, createWSClient, unstable_httpSubscriptionLink, httpBatchLink, splitLink, unstable_httpBatchStreamLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import SuperJSON from "superjson";
import { type AppRouter } from "~/server/api/root";
import { queryClient } from "./query-client";
import { env } from "~/env";

// Singleton for the query client
let clientQueryClientSingleton: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return queryClient;
  }
  // Browser: use singleton pattern to keep the same query client
  return clientQueryClientSingleton ??= queryClient;
};
export const api = createTRPCReact<AppRouter>();

/**
 * Inference helper for inputs.
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
{
  /* // Create WebSocket client
  const wsClient = createWSClient({
  url: 'ws://localhost:3000', // Adjust to your WebSocket server URL
  connectionParams: async () => {
    const sessionId = await getSessionId();
    return {
      'session-id': sessionId,
    };
  },
  });
  */
}
export function TRPCReactProvider(props: {
  children: React.ReactNode;
}) {
  const queryClient = getQueryClient();
  const [trpcClient] = useState(() => api.createClient({
    links: [loggerLink({
      enabled: op => process.env.NODE_ENV === "development" || op.direction === "down" && op.result instanceof Error
    }), unstable_httpBatchStreamLink({
      url: `${getBaseUrl()}/api/trpc`,
      transformer: SuperJSON
    }),
    // HTTP batching for other operations
    splitLink({
      condition: op => op.type === "subscription",
      true: unstable_httpSubscriptionLink({
        url: `${getBaseUrl()}/api/trpc`,
        transformer: SuperJSON
      }),
      false: httpBatchLink({
        url: `${getBaseUrl()}/api/trpc`,
        transformer: SuperJSON
      })
    })]
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="TRPCReactProvider" data-sentry-source-file="react.tsx">
      <api.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-source-file="react.tsx">
        {props.children}
      </api.Provider>
    </QueryClientProvider>;
}
function getBaseUrl() {
  // console.log("typeof window: ", typeof window);
  // console.log("locatoin window: ", window.location.origin);
  // console.log("alt link: ", env.NEXT_PUBLIC_APP_URL);
  if (typeof window !== "undefined") return window.location.origin;
  return `${env.NEXT_PUBLIC_APP_URL}`;
}