import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { z } from "zod"
import { isRedirectError } from "next/dist/client/components/redirect"
import { parseISO, format } from "date-fns"
import { formatInTimeZone, toZonedTime } from 'date-fns-tz'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number
    sizeType?: "accurate" | "normal"
  } = {}
) {
  const { decimals = 0, sizeType = "normal" } = opts

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"]
  if (bytes === 0) return "0 Byte"
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${sizeType === "accurate" ? accurateSizes[i] ?? "Bytest" : sizes[i] ?? "Bytes"
    }`
}

export function getErrorMessage(err: unknown) {
  const unknownError = "Something went wrong, please try again later."

  if (err instanceof z.ZodError) {
    const errors = err.issues.map((issue) => {
      return issue.message
    })
    return errors.join("\n")
  } else if (err instanceof Error) {
    return err.message
  } else if (isRedirectError(err)) {
    throw err
  } else {
    return unknownError
  }
}

export function timeSince(date: Date): string {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / 86400);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return 'just now';
  }
}

export function formatDateTimeString(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dateNum = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

  return {
    dateTime: `${day}, ${month} ${dateNum}, ${year}, ${formattedTime}`,
    date: `${day}, ${month} ${dateNum}, ${year}`,
    time: formattedTime
  };
}








export function formatDateTimeRange(startDateTimeString: string, endDateTimeString: string) {
  const start = formatDateTimeString(startDateTimeString);
  const end = formatDateTimeString(endDateTimeString);

  return {
    dateTime: start.date + ` ${start.time} - ${end.time}`,
    date: start.date,
    time: `${start.time} - ${end.time}`
  };
}

//Hnadle UTC string input
export function convertUTCToLocal(utcDateString: string, timeZone: string): string {
  // Parse the UTC datetime string
  const parsedDate = parseISO(utcDateString);

  // Format the parsed date in the specified timezone
  const localDateString = formatInTimeZone(parsedDate, timeZone, 'yyyy-MM-dd HH:mm:ss');

  // Return the local date string in a format suitable for formatDateTimeString
  return localDateString; // Ensure this is a valid date-time string
}

// New function to handle Date input
export function convertUTCDateToLocal(utcDate: Date | null, timeZone: string): string {
  if (utcDate === null) return "n/a"; // Return "n/a" if the input is null

  // Format the parsed date in the specified timezone
  const localDateString = formatInTimeZone(utcDate, timeZone, 'yyyy-MM-dd HH:mm:ss');

  // Return the local date string in a format suitable for formatDateTimeString
  return localDateString; // Ensure this is a valid date-time string
}