import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    AZURE_DATABASE_URL: z.string(),
    ALT_TIPTAP_PRO_TOKEN: z.string(),
    AZURE_ACR_LOGIN_SERVER: z.string(),
    AZURE_BLOB_CONTAINER_NAME: z.string(),
    AZURE_CDN_ENDPOINT: z.string(),
    AZURE_CDN_REGION: z.string(),
    AZURE_STORAGE_ACCOUNT_NAME: z.string(),
    AZURE_PUBLIC_STORAGE_ACCOUNT_ENDPOINT: z.string(),
    AZURE_STORAGE_ACCOUNT_ACCESS_KEY: z.string(),
    AZURE_STORAGE_ACCOUNT_SECRET_KEY: z.string(),
    AZURE_STORAGE_CONNECTION_STRING: z.string(),
    AZURE_VM_PUBLIC_IP: z.string(),
    CONVERT_API_KEY: z.string(),
    OPENAI_API_KEY: z.string(),
    SENDGRID_API_KEY: z.string(),
    SENDGRID_EMAIL_FROM: z.string(),
    TIPTAP_APP_ID: z.string(),
    TIPTAP_JWT_SECRET: z.string(),
    TIPTAP_PRO_TOKEN: z.string(),
    NODE_ENV: z
      .enum(["development", "test", "production", "demo"])
      .default("development"),
    AZURE_AD_CLIENT_ID: z.string(),
    AZURE_AD_CLIENT_SECRET: z.string(),
    AZURE_AD_TENANT_ID: z.string(),
    AZURE_AD_REDIRECT_URI: z.string(),
    NEXTAUTH_SECRET: z.string()
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string(),
    NEXT_PUBLIC_APP_URL: z.string(),
    NEXT_PUBLIC_ENVIRONMENT_SUBDOMAIN: z.string(),
    NEXT_PUBLIC_ENVIRONMENT_DOMAIN: z.string(),
    NEXT_PUBLIC_ENVIRONMENT_PROTOCOL: z.string()
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    AZURE_DATABASE_URL: process.env.AZURE_DATABASE_URL,
    ALT_TIPTAP_PRO_TOKEN: process.env.ALT_TIPTAP_PRO_TOKEN,
    AZURE_ACR_LOGIN_SERVER: process.env.AZURE_ACR_LOGIN_SERVER,
    AZURE_BLOB_CONTAINER_NAME: process.env.AZURE_BLOB_CONTAINER_NAME,
    AZURE_CDN_ENDPOINT: process.env.AZURE_CDN_ENDPOINT,
    AZURE_CDN_REGION: process.env.AZURE_CDN_REGION,
    AZURE_PUBLIC_STORAGE_ACCOUNT_ENDPOINT: process.env.AZURE_PUBLIC_STORAGE_ACCOUNT_ENDPOINT,
    AZURE_STORAGE_ACCOUNT_NAME: process.env.AZURE_STORAGE_ACCOUNT_NAME,
    AZURE_STORAGE_CONNECTION_STRING: process.env.AZURE_STORAGE_CONNECTION_STRING,
    AZURE_STORAGE_ACCOUNT_ACCESS_KEY: process.env.AZURE_STORAGE_ACCOUNT_ACCESS_KEY,
    AZURE_STORAGE_ACCOUNT_SECRET_KEY: process.env.AZURE_STORAGE_ACCOUNT_SECRET_KEY,
    AZURE_VM_PUBLIC_IP: process.env.AZURE_VM_PUBLIC_IP,
    CONVERT_API_KEY: process.env.CONVERT_API_KEY,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    SENDGRID_EMAIL_FROM: process.env.SENDGRID_EMAIL_FROM,
    TIPTAP_APP_ID: process.env.TIPTAP_APP_ID,
    TIPTAP_JWT_SECRET: process.env.TIPTAP_JWT_SECRET,
    TIPTAP_PRO_TOKEN: process.env.TIPTAP_PRO_TOKEN,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    AZURE_AD_CLIENT_ID: process.env.AZURE_AD_CLIENT_ID,
    AZURE_AD_CLIENT_SECRET: process.env.AZURE_AD_CLIENT_SECRET,
    AZURE_AD_TENANT_ID: process.env.AZURE_AD_TENANT_ID,
    AZURE_AD_REDIRECT_URI: process.env.AZURE_AD_REDIRECT_URI,
    NEXT_PUBLIC_ENVIRONMENT_SUBDOMAIN: process.env.NEXT_PUBLIC_ENVIRONMENT_SUBDOMAIN,
    NEXT_PUBLIC_ENVIRONMENT_DOMAIN: process.env.NEXT_PUBLIC_ENVIRONMENT_DOMAIN,
    NEXT_PUBLIC_ENVIRONMENT_PROTOCOL: process.env.NEXT_PUBLIC_ENVIRONMENT_PROTOCOL,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});