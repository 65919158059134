import * as React from "react";
import { cn } from "~/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
const InputVariants = cva("flex h-9 w-full rounded-md border border-input bg-transparent px-3 text-base transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50", {
  variants: {
    variant: {
      default: "",
      ghost: "border-none"
    },
    width: {
      full: "w-full",
      fit: "w-fit"
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
export interface InputProps extends Omit<React.ComponentPropsWithoutRef<"input">, "width">,
// Omit width to avoid conflict
VariantProps<typeof InputVariants> {
  asChild?: boolean;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  variant,
  width,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn(InputVariants({
    variant,
    width,
    className
  }))} ref={ref} {...props} />;
});
Input.displayName = "Input";
export { Input };